import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps-form"},[_c(VStepper,{attrs:{"alt-labels":"","non-linear":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"editable":this.validSteps.size >= 1,"complete":_vm.currentStep > 1,"step":"1"},on:{"click":function($event){return _vm.handleStep(1)}}},[_vm._v(" Основное ")]),_c(VDivider),_c(VStepperStep,{attrs:{"editable":this.validSteps.size >= 2,"complete":_vm.currentStep > 2,"step":"2"},on:{"click":function($event){return _vm.handleStep(2)}}},[_vm._v(" Получатель ")]),_c(VDivider),_c(VStepperStep,{attrs:{"editable":this.validSteps.size >= 3,"complete":_vm.currentStep > 3,"step":"3"},on:{"click":function($event){return _vm.handleStep(3)}}},[_vm._v(" Отправители ")]),_c(VDivider),_c(VStepperStep,{attrs:{"editable":this.validSteps.size >= 4,"complete":_vm.currentStep > 4,"step":"4"},on:{"click":function($event){return _vm.handleStep(4)}}},[_vm._v(" Товар ")]),_c(VDivider),_c(VStepperStep,{attrs:{"editable":this.validSteps.size >= 5,"complete":_vm.currentStep > 5,"step":"5"},on:{"click":function($event){return _vm.handleStep(5)}}},[_vm._v(" Доп. услуги ")])],1),_c(VStepperItems,{staticClass:"full-w"},[_c(VStepperContent,{attrs:{"step":"1"}},[_vm._t("step1")],2),_c(VStepperContent,{attrs:{"step":"2"}},[_vm._t("step2")],2),_c(VStepperContent,{attrs:{"step":"3"}},[_vm._t("step3")],2),_c(VStepperContent,{attrs:{"step":"4"}},[_vm._t("step4")],2),_c(VStepperContent,{attrs:{"step":"5"}},[_vm._t("step5")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }