<script>
import {bus} from "../../router/bus";

export default {
  name: 'NextPeriodRoute',
  
  props: {
    route: {
      type: [Array, Object]
    }
  },
  
  data() {
    return {
      ratelist: [],
    }
  },
  
  computed: {
    getNextMonth() {
      var D = new Date(this.route.on_date);
      D.setMonth(D.getMonth() + 1);
      return D
    },
    
    getNextPeriodDate() {
      var D = new Date(this.route.on_date);
      D.setMonth(D.getMonth() + 1);
      D.setDate(1)
      return D
    },

    rateListNextPeriodFilter(){
      return this.ratelist.filter(rate => rate.sea_start_dates.length > 0 ? 1 : 0)
    },
  },
  
  mounted() {
    this.getRateList()
    
    bus.$on('get_next_period', () => {
      this.getRateList()
    })
  },
  
  methods: {
    findNextPeriodRoute() {
      this.sortDates(this.ratelist)
      this.$emit('nextRateList', this.rateListNextPeriodFilter)
      bus.$emit('change_date', this.getNextPeriodDate);
    },
    
    getRateList() {
      let out_date = new Date(this.getNextPeriodDate)
      out_date.setDate(out_date.getDate());
      this.request_data = out_date

      const token = this.$_getsetting('token');
      const uri = this.$api + (token ? this.$methods.getRateList.url : this.$methods.getRates.url);

      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          ...(token && { "Authorization": `Bearer ${token}` })
        }
      };
        
      const params = {
        on_date: out_date,
        place_to: this.route.place_to,
        place_from: this.route.place_from,
        unit_code: this.route.unit_code,
        client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99
      };

      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.ratelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },

    sortDates(ratelist) {
      for (let i = 0; i < ratelist.length; i++) {
        if (ratelist[i].sea_start_dates) {
          let dates = ratelist[i].sea_start_dates.split(',')
          ratelist[i].sea_start_dates = dates.sort(function (a, b) {
            return new Date(a) - new Date(b);
          })
        }
      }
    },
  },
}
</script>

<template>
  <div class="next-period-route" v-if="rateListNextPeriodFilter.length > 0">
    <div class="next-period-route__info">
      <p class="next-period-route__info-title" @click="findNextPeriodRoute">
        Рекомендуем ознакомиться со ставками на {{getNextMonth.toLocaleString('ru', { month: 'long' })}}
      </p>
    </div>
    <div class="next-period-route__info-title"> 
      <p @click="findNextPeriodRoute">Смотреть</p> 
      <button @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.702 1.712A1 1 0 0 0 10.298.288L5.962 4.56 1.708.294A1 1 0 1 0 .292 1.706l4.256 4.268-4.26 4.324a1 1 0 0 0 1.424 1.404l4.25-4.314 4.332 4.32a1 1 0 1 0 1.412-1.416l-4.33-4.318 4.326-4.262Z" fill="#305CA8"/>
        </svg>      
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.next-period-route{
  width: 100%;
  background-color: var(--blue-20);
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  
  &__info {
    padding: 8px 12px;

    @include font-body-3;
    
    &-title {
      color: var(--blue-70);
      font-size: 14px;
      cursor: pointer;
      line-height: 18px;
      text-transform: none;
      display: flex;
      align-items: center;
      @include font-description-semibold-1;

      button {
        margin: 3px 12px 0;
        width: 12px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}




</style>
