<script>
import { reachYMGoal } from "@/utils/metrika";
import { bus } from "../../router/bus";
import DesktopMenu from "./DesktopMenu.vue";

export default {
  name: "MainHeader.vue",

  components:{
    DesktopMenu
  },

  props: {
    type: {
      type: String,
      required: false,
      default: () => "light",
      validator: (value) => ["light", "dark" ].includes(value),
    },

    isNewYear: Boolean
  },

  data: () => ({
    isSetting: 0,
    authStatus: !!localStorage.getItem('is_auth'),
    logoNYVed: require("../../assets/img/vedexx-logo/logo-ny-vedexx.webp"),
    logoIcon: require("../../assets/img/vedexx-logo/logo_light.svg"),
  }),

  computed: {
    isAuth() {
      return this.authStatus;
    },

    isToken() {
      return !!this.$_getsetting('token');
    },
  },

  mounted() {
    this.checkIsSiteAvailable()

    if (!this.isAuth && !this.$_getsetting('client_id')) {
      this.$_setsetting('client_id', -99)
    }

    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting('is_auth')
    })

    this.showRegisterForm()
  },

  methods: {
    gotoMain() {
      if (this.$route.name !== "MainPage") {
        this.$router.push("/")
      } else {
        bus.$emit('hide_rate')
        window.scrollTo(0, 0)
      }
    },

    gotoProfile() {
      if (this.$route.name !== "Profile") {
        this.$router.push("/Profile")
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    checkIsSiteAvailable() {
      const uri = this.$api + this.$methods.getSettingByName.url
      const headers = {
        headers: {
          "Accept": "application/json",
        }
      };

      const params = {
        setting_name: "is_under_construction"
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.isSetting = JSON.parse(data.message)
          if (this.isSetting.is_under_construction == 1) {
            this.$router.push("/ErrorPage")
          }
        }
      });
    },

    showRegisterForm(){
      const params = new URLSearchParams(window.location.search)
      if (params.get('action') === 'registration'){
        bus.$emit("showloginform", 'signup')
      }
    },
  }
}
</script>

<template>
  <div
    class="main-header"
    :class="[
        type === 'light' ? 'main-header-light' : 'main-header-dark',
        isNewYear ? 'main-header__NY' : ''
    ]"
  >
  <div class="main-header main-header__container">
  <div class="main-header__info-items">
    <div
      class="main-header__logo"
      @click="gotoMain(), addMetrics('LogoTap')"
    >
      <img :src="isNewYear ? logoNYVed : logoIcon" alt="vedexx-logo"/>
    </div>

    <!-- <div class="main-header__login-btns-contact_links">
        <a href="tel:+78005550999"  @click="addMetrics('SPBphonetap')">+7(800) 555-09-99</a>
        <a href="mailto:spb@vedexx.com" @click="addMetrics('Email')">spb@vedexx.com</a>
      </div> -->

    </div>
      <DesktopMenu/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-header-light {
  height: 84px;
  line-height: 84px;

  @media (max-width: 743px) {
    display: none;
  }
}

.main-header-dark {
  height: 72px;
  line-height: 68px;

  @media (max-width: 743px) {
    display: none;
  }
}

.main-header {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0;
  align-items: center;
  align-content: center;

  &__NY{
    position: relative;

    &:before {
      position: absolute;
      content: "";
      background-image: url('../../assets/img/vedexx-logo/snowflake.webp');
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      background-size: cover;
      background-position: bottom;
      z-index: -1;
    }

    .main-header__logo{
      margin-top: -14px;

      >img{
        object-fit: contain;
      }
    }
  }

  &__container{
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: $tablet-width){
      padding: 32px;
      width: 100%;
    }

    @media (max-width: $mobile-width){
      padding: 16px;
    }
  }

  @media (max-width: 700px) {
    display: none;
  }

  &__logo {
    position: relative;
    cursor: pointer;

    > img {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 121px;
      height: auto;

      @media (max-width: 600px) {
        position: relative;
        width: 100%;
      }
    }

    &-mobile-text {
      text-transform: uppercase;
      color: var(--main-bg-color);
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      display: inline;
      align-items: center;
      position: absolute;
      font-size: 25px;
      line-height: 28px;
      margin-left: -110px;
      top: 4px;
      margin-top: 20px;
      letter-spacing: 0.01em;

      @media (min-width: 601px) {
        display: block;
      }
    }
  }

  &__login-btns {
    float: right;
    position: relative;
    display: flex;
    flex-direction: row;

    &-contact_links {
      color: white;
      text-align: end;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      line-height: 12px;
      top: 0;
      margin: auto $size-12;

      @media (min-width: 1919px) {
        font-size: $size-16;
        margin: auto $size-16;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    &-btn {
      text-transform: none;

      @include font-description-semibold-1;
      font-weight: 700;

      @media (max-width: 600px) {
        display: none;
      }
    }

    &-mobile {
      display: none;
      margin-top: 10px;

      @media (max-width: 600px) {
        display: inline-block;
      }
    }

    &-divider {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0;
      width: 1.5px;
      height: 30px;
      background: #FFFFFF;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  &__info-items{
    display: flex;
    align-items: center;
    gap: 0 24px;

    .main-header__login-btns-contact_links{
      display: flex;
      flex-direction: column;
      gap: 6px 0;

      a {
        @include font-description-1;
      }
    }
  }
}

a {
  color: white !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.main-header__login-btns-btn-img{
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.main-header__login-btns-contact_link{
    @include font-body-2;
    line-height: 14px;
}

</style>

