<script>
import AppLoader from "@/components/ui/AppLoader.vue";

export default {
  name: "AppInput",

  props: {
    label: String,
    description: String,
    leftIcon: String,
    rightIcon: String,
    placeholder: String,
    type: { type: String, default: "text" },
    value: { required: true },
    loading: Boolean,
    disabled: Boolean,
    success: Boolean,
    required: Boolean,
    editable: Boolean,
    rules: { type: Array, default: () => [] },
    phoneMask: Boolean,
    dark: { type: Boolean, default: false },
    disableInput: { type: Boolean, default: false },
    suggestions: { type: Array, default: () => [] },
  },

  components: {
    AppLoader
  },

  data() {
    return {
      inputValue: "",
      isEditing: false,
      editIcon: require('../../assets/img/icons/pencil.svg'),
      error: false,
      errorMessage: null,
      // touched: false,

      showErrors: false,
      filteredSuggestions: [],
    }
  },

  mounted() {
    this.inputValue = this.value;
  },

  computed: {
    showError() {
      return (this.showErrors) || !!this.errorMessage && this.error;
    }
  },

  watch: {
    value(newValue) {
      this.inputValue = newValue;
      this.errorMessage = null
      this.error = false
      this.filterSuggestions()
    },

    inputValue(newValue) {
      this.$emit('input', newValue)
      this.filterSuggestions()
    },
  },

  methods: {
    onBlur() {
      // this.touched = true
      this.validate()
      this.filteredSuggestions = []
    },

    onSubmit() {
      this.showErrors = true;
      this.validate();
    },

    validate() {
      let value = this.inputValue
      let isValid = true
      this.errorMessage = null
      this.error = false
      for (const rule of this.rules) {
        const result = rule(value)
        if (result !== true) {
          this.error = true
          isValid = false
          this.errorMessage = result
          break;
        }
      }
      return isValid
    },

    handleInput(e){
      if (this.disableInput){
        e.preventDefault()
        e.target.blur()
      }
    },

    filterSuggestions() {
      if (this.inputValue) {
        this.filteredSuggestions = this.suggestions.filter(suggestion =>
          suggestion.toLowerCase().includes(this.inputValue.toLowerCase())
        )

        const index = this.filteredSuggestions.indexOf(this.inputValue)
        if (index !== -1) {
          this.filteredSuggestions.splice(index, 1)
        }
      } else {
        this.filteredSuggestions = []
      }
    },

    selectSuggestion(suggestion) {
      this.inputValue = suggestion
      this.filteredSuggestions = []
      this.$emit('input', suggestion)
    },

    disablePrevent(event) {
      event.preventDefault()
    },

  }
}
</script>

<template>
  <div :class="['input-container', { 'has-label': label, 'has-description': description, 'loading': loading }]">
    <label v-if="label" :class="['input__label', {'dark' : dark}]">
      {{ label }}
      <span v-if="required">*</span>
    </label>

    <div :class="['input-wrapper',
      {
        'input-wrapper-error': showError && !dark,
        'input-wrapper-dark-error': showError && dark,
        'input-wrapper-disabled': disabled || (!isEditing && editable),
        'input-wrapper-success': success
      }]"
      @click="$emit('click')"
    >
      <img
        alt="left icon"
        v-if="leftIcon"
        :src="leftIcon"
        :class="['input-wrapper__icon left-icon']"
      />

      <input v-if="phoneMask" v-phone :type="type" :disabled="disabled || (!isEditing && editable) || loading"
        :placeholder="placeholder" v-model="inputValue" @blur="onBlur" />

      <input
        v-else
        :type="type"
        :disabled="disabled || (!isEditing && editable) || loading"
        :placeholder="placeholder"
        v-model="inputValue"
        @blur="onBlur"
        @keydown="handleInput"
        @focus="handleInput"
        :class="disableInput ? 'input-calendar' : ''"
      />

      <AppLoader v-if="loading" class="loader" :size="24" />

      <img alt="right icon" v-if="rightIcon || editable" :src="editable ? editIcon : rightIcon"
        class="input-wrapper__icon right-icon"
        :class="{ 'right-icon-edit' : disabled && editable, 'disabled': (!isEditing && editable) }"
        @click="isEditing = !isEditing"
      />

      <div class="suggestions" v-if="filteredSuggestions.length">
        <ul>
          <li v-for="(suggestion, index) in filteredSuggestions" :key="index" @click="selectSuggestion(suggestion)"
            @mousedown="disablePrevent">
            {{ suggestion }}
          </li>
        </ul>
      </div>

      
    </div>

    <small>
      <span v-if="description">
        {{ description }}
      </span>
      <span :class="['error-message', { 'dark': dark }]" v-if="showError && error">
        {{ errorMessage }}
      </span>
    </small>
  </div>
</template>

<style scoped lang="scss">
.input-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  position: relative;

  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;

    > span {
      color: var(--red-60);
    }
  }

  .input__label{
    color: var(--grey-70);

    &.dark{
      color: #FFF;
    }
  }

  small {
    color: var(--grey-60);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: -12px;
    height: 18px;

    > span {
      &.error-message {
        color: var(--red-100);

        &.dark{
          color: var(--red-30);
        }
      }
    }
  }

  &.loading {
    opacity: 0.5;

    .loader {
      opacity: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-40);
  border-radius: 12px;
  background-color: #FFFFFF;
  height: 40px;
  margin-bottom: 16px;
  width: 100%;
  transition-duration: 0.2s;

  position: relative;

  @media (max-width: $mobile-width) {
    min-height: 40px;
    height: 100%;
  }

  &:hover {
    border: 1px solid var(--grey-70);
  }

  &:focus-within {
    border: 1px solid var(--blue-70);
    box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
  }

  &-error {
    border: 1px solid var(--red-100);
    box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);

    &:hover,
    &:focus-within {
      border: 1px solid var(--red-100);
      box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);
    }
  }

  &-dark-error{
    color: var(--red-30);
    box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);

    &:hover,
    &:focus-within {
      border: 1px solid var(--red-30);
      box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);
    }
  }

  &-success {
    border: 1px solid var(--green-100);
    box-shadow: 0 0 0 2px rgba(179, 218, 161, 0.50);

    &:hover,
    &:focus-within {
      border: 1px solid var(--green-100);
      box-shadow: 0 0 0 2px rgba(179, 218, 161, 0.50);
    }
  }

  &-disabled {
    border: 1px solid var(--grey-50);
    background-color: var(--grey-10);
    cursor: not-allowed;
    color: var(--grey-70);

    &:hover {
      border: 1px solid var(--grey-50);
    }

    > input {
      cursor: not-allowed;
      color: var(--grey-70) !important;
    }
  }

  > input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--blue-100);

    &::placeholder {
      color: var(--grey-50);
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    position: absolute;
  }
}

.left-icon {
  left: 12px;
}

.right-icon {
  right: 12px;

  &-edit {
    cursor: pointer;
  }

  &.disabled {
    cursor: pointer;
    opacity: 0.6;
  }
}

.has-label .input-container {
  height: 80px;
}

.has-description .input-container {
  height: 80px;
}

.input-calendar{
  cursor: pointer;
}

.suggestions {
  position: absolute;
  background: white;
  border-radius: 12px;
  border: 1px solid var(--grey-50);
  padding: 10px;
  top: 39px;
  z-index: 10;

  ul {
    list-style: none;
    display: flex;
    margin: unset;
    padding: unset;
    flex-direction: column;
    gap: 10px 0;
    cursor: pointer;
  }

  li {
    @include font-body-3;
  }
}
</style>
