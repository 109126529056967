<script>
import CVTBDateInput from "../../common/CVTBDateInput";

export default {
  components: {
    CVTBDateInput
  },
  
  props: {
    contractId: {
      type: Number,
      required: true
    }
  },
  
  data() {
    return {
      date_from: '',
      date_to: '',
      showStatus: false,
      elements: {
        
        date_from: {
          caption: "С",
          name: "date_from",
          disabled: false,
          alt: "",
          type: "date"
        },
  
        date_to: {
          caption: "По",
          name: "date_to",
          disabled: false,
          alt: "",
          type: "date"
        },
      },
    }
  },
  
  methods: {
    setCompleteDocRequest() {
      const uri = this.$api + this.$methods.setCompleteDocRequest.url
      const token = this.$_getsetting('token')

      const headers = {
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }
      };

      const params = {
        client_id: this.$_getsetting('client_id'),
        date_from: this.date_from,
        date_to: this.date_to,
        contract_id: this.contractId,
      }

      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0){
          this.showStatus = true
          
          setTimeout(() => {
            this.$emit('close')
          }, 1800);
        }
      });
    },
  }
}
</script>

<template>
  <v-card class="pa-4">
    <v-card-title class="text-h5">
      Запрос акта сверки
    </v-card-title>
    
    <v-card-subtitle class="mt-2">
      Укажите период запрашиваемой сверки
    </v-card-subtitle>
    
    <v-form @submit.prevent="setCompleteDocRequest">
      <v-card-actions class="mx-3">
        <CVTBDateInput
          class="mr-3"
          @input="date_from = $event"
          @selected="date_from = $event.value"
          :element="elements.date_from"
          :val="date_from"
        />
        
        <CVTBDateInput
          class="ml-3"
          @input="date_to= $event"
          @selected="date_to = $event.value"
          :element="elements.date_to"
          :val="date_to"
        />
      </v-card-actions>
      
      <span class="mx-5" v-if="showStatus == true">Запрос подан в отдел бухгалтерии</span>
      
      <v-card-actions class="mt-8">
        <v-spacer></v-spacer>
        <v-btn
          color="var(--main-bg-color)"
          text
          type="submit"
        >
          Запросить
        </v-btn>
        <v-btn
          color="var(--main-bg-color)"
          text
          type="button"
          @click="$emit('close')"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<style>

</style>
