<script>
import {bus} from "../../router/bus";
export default {
  name: 'AlarmGoodsForm',
  
  data: () => ({
    questions: [],
    valid: true,
    answers: [],
    answer: '',
    
    is_fill: 0,
    is_hide: 0,
    
    hs_code: '',
  }),
  
  mounted() {
    this.getQuestions()
  },
  
  methods: {
    sendAnswers() {
      const uri = this.$api + this.$methods.setClientGoodsQuestionnaire.url
      const token = this.$_getsetting('token')

      const headers = {
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      
      const params = {
        is_fill: 1,
        is_hide: 0,
        data: this.answers
      };

      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0){
          this.$emit('close')
          bus.$emit("show_notify", {
            color: "success",
            notifytext: 'Спасибо, ваши ответы сохранены!'
          });
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getQuestions(){
      this.$postapi(this.$address + this.$getters.getClientGoodsQuestionnaire.uri, {
        method: this.$getters.getClientGoodsQuestionnaire.name,
      }).then(data => {
        if (data.error == 0){
          this.questions = JSON.parse(data.message)
          this.fillAnswers()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    validate () {
      this.$refs.form.validate()
      if (this.valid === true) {
        this.sendAnswers()
      }
    },
    
    fillAnswers() {
      this.questions.forEach(question => {
        this.answers.push({
          id: question.id,
          prop_name: question.prop_name,
          value: null,
          text_value: null,
          name: question.name,
        })
      });
    }
  }
}
</script>
<template>
  <v-form
    class="alarm-form"
    ref="form"
    v-model="valid"
    @submit.prevent="validate"
  >
    <div class="alarm-form__title">
      Пожалуйста, заполните анкету. Это требуется только перед вашей первой заявкой.
    </div>
    <div
      v-for="(answer, index) in answers"
      :key="index"
      class="alarm-form__question"
    >
      <div class="alarm-form__question-title">{{answer.name}}</div>
      
      <div class="alarm-form__question-radio">
        <v-radio-group
          row
          :rules="[v => v != null || 'Это обязательный вопрос']"
          v-model="answer.value"
        >
          <v-radio
            on-icon="mdi-checkbox-outline"
            off-icon="mdi-checkbox-blank-outline"
            label="Да"
            color="black"
            :value="1"
          >
          </v-radio>
          <v-radio
            on-icon="mdi-checkbox-outline"
            off-icon="mdi-checkbox-blank-outline"
            label="Нет"
            color="black"
            :value="0"
          >
          </v-radio>
        </v-radio-group>
      </div>
      
      <v-text-field
        v-if="(answer.id == 333 || answer.id == 334) && answer.value === 1"
        height="40px"
        class="mt-2"
        v-model="answer.text_value"
        label="ТНВЭД"
        outlined
        color="var(--main-orange)"
        solo
        flat
      ></v-text-field>
    </div>
    
    <v-card-actions class="mt-2">
      <v-spacer></v-spacer>
      <v-btn
        color="var(--main-orange)"
        rounded
        height="38"
        elevation="0"
        class="alarm-form__btn"
        type="submit"
      >
        Отправить
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<style scoped lang="scss">
.alarm-form {
  padding: 40px;
  
  &__question {
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    
    &-radio {
      min-width: 160px;
      margin-left: 20px;
    }
  }
  
  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  &__btn {
    text-transform: none;
    letter-spacing: 0.0239286em;
    font-weight: 600;
    color: #FFFFFF;
    position: relative;
    padding: 10px 16px !important;
  }
}
</style>
