<script>
export default {
  name: "AppInputSlider",
  model: {
    prop: 'value',
  },
  props: {
    label: {
      type: String,
      required: true,
    },

    minValue: {
      type: Number,
      default: 0,
      required: false,
    },

    maxValue: {
      type: Number,
      required: true,
      default: 0,
    },

    value: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    sliderValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>

<template>
  <div class="input-slider">
    <label class="input-slider__title" v-if="label">{{ label }}</label>
    <div class="input-slider__items">
      <v-slider
        class="input-slider__range"
        v-model="sliderValue"
        :min="minValue"
        :max="maxValue"
        :thumb-label=" value !== maxValue ? 'always' : ''"
      >
        <template v-slot:thumb-label="{ value }">
          {{ `до ${value ? value.toLocaleString() : 0} ₽` }}
        </template>
      </v-slider>
      <span class="input-slider__max-value">{{ `${maxValue ? maxValue.toLocaleString() : 0} ₽` }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input-slider {
  position: relative;
  z-index: 100;
  width: 100%;

  &__items{
    display: flex;
    align-items: center;
    gap: 0 16px;
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    display: block;

    @media screen and (max-width: 360px) {
      font-size: 12px;
    }
  }

  &__max-value,
  &__init-value {
    position: relative;
    bottom: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--grey-70);
  }

  &__init-value {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-weight: 500;
    color: var(--grey-100);
  }

  &__range {
    margin-top: 11px;

    @media screen and (max-width: 360px) {
      margin-top: 0;
    }
  }
}
</style>
