import {bus} from "@/router/bus";

export function clearSession(router) {
  const settingsToRemove = [
    'token', 'user_id', 'login', 'email', 'is_auth', 'client_id', 'fio', 
    'clnt_name', 'manager_email', 'is_show_bm_messages', 'manager_name',
    'manager_phone', 'place_from', 'place_to', 'unit_code', 'on_date'
  ];

  settingsToRemove.forEach((key) => localStorage.removeItem(key));

  bus.$emit('auth-update', {login: false});

  setTimeout(() => {
    bus.$emit("show_notify", {
      color: "warn",
      duration: 4500,
      notifytext: "Время сессии истекло по причине отсутствия активности на сайте в течение 12 часов. Просим пройти авторизацию повторно."
    });
  }, 400);

  if (router.currentRoute.name !== "MainPage") {
    router.push("/")
  }
}
