<script>
import { bus } from "@/router/bus";
export default {
  name: 'RatePageSchedule',
  props:{
    rate: { type: Object },
    isAuth: { type: Boolean },
    schedule: { type: Array }
  },

  data: () => ({
    noIcon: require("../../assets/img/icons/cancel.svg"),
    yesIcon: require("../../assets/img/icons/check-default-green.svg"),
  }),
  
  computed: {
    getClientId() {
      return Number(this.$_getsetting('client_id'))
    }
  },
  
  methods: {
    openLineInfo() {
      bus.$emit('showLineInfo')
    },
  
    getDate(value) {
      if (value != null)
        return (new Date(value)).toLocaleString("ru", {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timezone: 'UTC'
        });
      else
        return ' '
    },
  },
}
</script>

<template>
  <div class="mt-editor-form">
    <table class="schedule-table">
      <thead class="schedule-table__head">
        <tr>
          <td>Место отправки</td>
          <td>Выход в море</td>
          <td>Прибытие</td>
          <td>Прибытие в порт</td>
          <td>Подрядчик</td>
          <td>Наименование судна</td>
          <td>Роутинг</td>
        </tr>
      </thead>
      <tr class="schedule-table__body" :class="item.is_no_places ? 'rate-card-detail-service__inactive' : ''"
        v-for="item in schedule" :key="item.id">
        <td>{{item.place_from_name}}</td>
        <td class="schedule-table__body-date schedule-table__body-date-departure">{{getDate(item.sea_start_date)}}</td>
        <td>{{item.place_to_name}}{{isAuth ? '(' + item.point_to_name + ')' : ''}}</td>
        <td class="schedule-table__body-date">{{getDate(item.sea_end_date)}}</td>
        <td v-if="Number(rate.is_hide_line) === 0 || getClientId === -1" @click="openLineInfo">
          <span
            :class="Number(rate.has_line_info) === 1 ? 'schedule-table__body-line-link' : 'schedule-table__body-line'">
            {{rate.line_name}}
          </span>
        </td>
        <td v-else class="schedule-table__body-line">Vedexx</td>
        <td v-if="(getClientId === -1 || Number(rate.is_hide_line) === 0) && isAuth">{{item.vassel_name}}</td>
        <td v-else>TBN</td>
        <td class="td-center">
          <span v-if='rate.transshipment_port_name.length > 0'>
            Via {{rate.transshipment_port_name}}
          </span>
          <span v-else>Прямой роутинг</span>
        </td>
      </tr>
    </table>

    <table class="schedule-table-tablet">
      <thead class="schedule-table__head">
        <tr>
          <td>Место отправки и прибытия</td>
          <td>Выход в море и прибытие в порт</td>
          <td>Подрядчик</td>
          <td>Судно</td>
          <td>Роутинг</td>
        </tr>
      </thead>
      <tr class="schedule-table__body" :class="item.is_no_places ? 'rate-card-detail-service__inactive' : ''"
        v-for="item in schedule" :key="item.id">
        <td>
          <div class="schedule-table-tablet__places">
            {{item.place_from_name}}
            <img class="schedule-table-tablet__places-icon" src="../../assets/img/icons/rate-page-chevron.svg"
              alt="arrow">
            {{item.place_to_name}}{{isAuth ? '(' + item.point_to_name + ')' : ''}}
          </div>
        </td>
        <td class="schedule-table__body-date schedule-table__body-date-departure">
          <div>
            {{getDate(item.sea_start_date)}}
            <br>
            {{getDate(item.sea_end_date)}}
          </div>
        </td>
        <td v-if="Number(rate.is_hide_line) === 0 || getClientId === -1" @click="openLineInfo">
          <span
            :class=" Number(rate.has_line_info) === 1 ? 'schedule-table__body-line-link' : 'schedule-table__body-line'">
            {{rate.line_name}}
          </span>
        </td>
        <td v-else class="schedule-table__body-line">Vedexx</td>
        <td v-if="(getClientId === -1 || Number(rate.is_hide_line) === 0) && isAuth">{{item.vassel_name}}</td>
        <td v-else>TBN</td>
        <td class="td-center">
          <!-- <img :src="!item.is_not_direct_route ? yesIcon : noIcon" alt="icon"/> -->

          <span v-if='rate.transshipment_port_name.length > 0'>
            Via {{rate.transshipment_port_name}}
          </span>
          <span v-else>Прямой роутинг</span>
        </td>
      </tr>
    </table>

    <table class="schedule-table-mobile">
      <tr v-for="item in schedule" :key="item.id" class="schedule-table__body">
        <td>
          <table>
            <tr class="card-path-table__tr-body">
              <td class="top-align">Выход в море</td>
              <td class="schedule-table-mobile__places">
                {{item.place_from_name}}
                <span>
                  {{getDate(item.sea_start_date)}}
                </span>
              </td>
            </tr>

            <tr class="card-path-table__tr-body">
              <td class="top-align">Прибытие</td>
              <td class="schedule-table-mobile__places">
                {{item.place_to_name}}{{isAuth ? '(' + item.point_to_name + ')' : ''}}
                <span>
                  {{getDate(item.sea_end_date)}}
                </span>
              </td>
            </tr>

            <tr class="card-path-table__tr-body">
              <td v-if="isAuth">Подрядчик</td>
              <td v-if="Number(rate.is_hide_line) === 0 || getClientId === -1" @click="openLineInfo">
                <span
                  :class="Number(rate.has_line_info) === 1 ? 'schedule-table__body-line-link' : 'schedule-table__body-line'">
                  {{rate.line_name}}
                </span>
              </td>
              <td v-else class="schedule-table__body-line">Vedexx</td>
            </tr>

            <tr class="card-path-table__tr-body">
              <td>Судно</td>
              <td v-if="(getClientId === -1 || Number(rate.is_hide_line) === 0) && isAuth">{{item.vassel_name}}</td>
            </tr>

            <tr class="card-path-table__tr-body">
              <td>Роутинг</td>
              <td class="td-center">
                <!-- <img :src="!item.is_not_direct_route ? yesIcon : noIcon" alt="icon"/> -->
                <span v-if='rate.transshipment_port_name.length > 0'>
                  Via {{ rate.transshipment_port_name}}
                </span>
                <span v-else>Прямой роутинг</span>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="scss">
.mt-editor-form {
  background-color: #fff;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: $mobile-width) {
    overflow: unset;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  @media (min-width: $mobile-width) and (max-width: $tablet-width) {
    width: calc(100% / 4);
  }
  
  @media (max-width: $mobile-width) {
    width: calc(100% / 2);
  }
}

.top-align {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.schedule-table-mobile {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  display: none;

  @media print {
    display: none !important;
  }
  
  @media (max-width: $mobile-width ) {
    display: block;
  }
  
  &__places {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    
    > span {
      margin-top: 8px;
      color: var(--grey-100);
      letter-spacing: 0.14px;

      @include font-description-semibold-0;
    }
  }
}

.schedule-table-tablet {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  display: none;

  @media print {
    display: none;

    @media (max-width: $mobile-width){
      display: block;

      td{
        white-space: unset !important;
        font-size: 12px;
      }
    }

  }
  
  @media (min-width: $mobile-width) and (max-width: $tablet-width) {
    display: block;
  }
  
  &__places {
    display: flex;
    flex-direction: column;
    bottom: -10px;
    
    > img {
      transform: rotate(90deg);
      width: 6px !important;
      margin: auto;
      height: 12px !important;
    }
  }
}

.schedule-table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  
  @media (max-width: $tablet-width) {
    display: none;
  }
  
  &__head {
    color: var(--grey-70);
    letter-spacing: 0.12px;

    @include font-description-semibold-1;
    
    td {
      padding: 12px 0;
      white-space: nowrap;

      @media (max-width: $tablet-width){
        padding: 12px 20px;
      }

      @media print {
        padding: 4px 0;

        &:nth-child(2),
        &:nth-child(3){
          width: 100px;
          padding-right: 16px;
          text-align: center;
        }
      }
    }
  }
  
  &__body {
    color: var(--grey-70);
    letter-spacing: 0.16px;
    border-top: 1px solid #ECECEC;

    @include font-body-2;
    
    @media (max-width: $mobile-width) {
      font-size: 14px;
      letter-spacing: 0.14px;
      border-top: none;
      
      &:not(:last-child) {
        border-bottom: 1px solid #ECECEC;
      }
    }
    
    td {
      padding: 30px 0;
      text-align: left;
      white-space: nowrap;

      @media (max-width: $tablet-width){
        padding: 30px 20px;
      }

      @media (max-width: $mobile-width){
        white-space: normal;
        word-wrap: break-word;
        width: 100%;

        &:first-child{
          padding-right: 20px;
        }
      }

      @media print {
        padding: 4px 0 !important;

        @media (max-width: $mobile-width){
          &:nth-child(2),
          &:nth-child(3){
            text-align: center;
          }
        }
      }
      
      @media (max-width: $mobile-width) {
        padding: 16px 0;
      }
      
      img {
        margin-left: 40px;
        height: 24px;
        width: 24px;
      }
    }
    
    &-date {
      @include font-body-bold-3;

      color: var(--grey-100);
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.14px;
      position: relative;
      // display: flex;
      // top: 2px;

      @media (max-width: $tablet-width) {
        top: 0;
      }
      
      &-departure {

        &:after {
          content: '';
          display: inline-block;
          width: 12px;
          margin-left: 30px;
          height: 16px;
          background: url("../../assets/img/icons/rate-page-chevron.svg") no-repeat;
          background-size: contain;
          position: relative;
          top: -1px;

          @media (max-width: $tablet-width){
            content: unset;
          }
        }
      }
    }
    
    &-line {
      letter-spacing: 0.14px;

      @include font-description-0;
      
      &-link {
        color: var(--blue-50);
        text-decoration-line: underline;
        cursor: pointer;
        
        &:active {
          text-decoration: none;
        }
      }
      
      @media print and (max-width: $mobile-width){
        font-size: 12px;
      }
    }
  }
}
</style>
