<script>
import { bus } from "@/router/bus";
import PublicHolidaysBtn from "./PublicHolidaysBtn.vue";
import AppDropDown from "../ui/AppDropDown.vue"
import AppButton from "../ui/AppButton.vue"
import HistoryRateSearch from "./HistoryRateSearch.vue";
import AppMultiRadioButtons from "../ui/AppMultiRadioButtons.vue";
import AppInput from "../ui/AppInput.vue";
import { reachYMGoal } from "@/utils/metrika";

export default {
  name: "FindRatePanel.vue",
  
  components: {
    PublicHolidaysBtn,
    AppDropDown,
    AppButton,
    HistoryRateSearch,
    AppMultiRadioButtons,
    AppInput
  },

  data() {
    return {
      lang: 'en',
      searchType: 'fn_get_ratelist_json',
      historyData: [],
      historyString: [],
      
      place_from: [],
      place_to: [],
      unit_code: [],
      places_from: [],
      places_to: [],
      unit_codes: {},
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: null,
      showCalendar: false,

      inputRules: [
          v => !!v || 'Не выбрано',
      ],
      
      windowWidth: window.innerWidth,

      searchParams: {},
      holidaysData: [],
    }
  },
  
  computed: {
    currentUserId(){
      return +this.$_getsetting('client_id')
    },
  },
  
  watch: {
    on_date() {
      this.dateFormatted = this.formatDate(this.on_date)
    },
  
    place_from(val){
      if(val){
        this.getHolidaysInfo()
      }
    }
  },
  
  mounted() {    
    this.getreferencedata('lgst_place_from_wg')
    this.getreferencedata('lgst_place_to_wg')
    this.getreferencedata('lgst_cont_types')
    
    if (this.$_getsetting('is_auth') == 'true') {
      this.getSearchParamsHistory()
    }
    
    bus.$on("changeplacefrom", (data) => {
      this.changePlaceFrom(data)
    });
    
    bus.$on("changeplaceto", (data) => {
      this.changePlaceTo(data)
    });

    bus.$on("changeSearchParams", (data) => {
      this.changePlaceTo(data.place_to)
      this.changePlaceFrom(data.place_from)
      this.changeUnitCode(data.unit_code)
      this.changeDate(data.on_date)
    });

    bus.$on("change_date", (data) => {
      this.changeDate(data)
    });

    this.getCurrentSearchParams()
  },

  updated(){
    this.setCurrentSearchParams()

    bus.$emit('getFindRate', (this.$refs))
  },
  
  methods: {
    checkLang(str) {
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
        } else {
          this.lang = "en"
        }
      }
    },
  
    getSearchParamsHistory() {
      const uri = this.$api + this.$methods.getSearchParamsHistory.url
      const token = this.$_getsetting('token')

      const headers = {
          headers: {
              "Accept": "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
          }
      };
      
      const params = {
        search_type: "fn_get_ratelist_json"
      };

      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.historyString =JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.historyData = JSON.parse(this.historyString[0].result)
        }
      })
    },
    
    getreferencedata(referencename) {
      const uri = this.$api + this.$methods.getReferenceData.url
      const params = {
        reference_name: referencename
      };

      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };

      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          switch (referencename) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_place_to_wg':
              this.places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_cont_types':
              this.unit_codes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    
    searchFromHistory(index) {
      this.changePlaceFrom(this.historyData[index].places_from[0].id)
      this.place_to = this.historyData[index].places_to[0]
      this.unit_code = this.historyData[index].unit_type[0]
      this.changeUnitCode(this.historyData[index].unit_type[0].id)
      this.search()
    },
    
    search() {
      if (this.$refs.place_from.validate() && this.$refs.place_to.validate() && this.$refs.unit_code.validate()) {
        setTimeout(this.sendRequest, 200);
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Указаны не все параметры для поиска!",
        });
      }
    },
    
    sendRequest() {
      if (this.dateFormatted === null) {
        this.dateFormatted = this.formatDate(this.on_date)
      }
      this.openRateListPage()
    },

    openRateListPage() {
      var queryParams = {
        place_from: this.place_from ? this.place_from.id : null,
        place_to: this.place_to ? this.place_to.id : null,
        unit_code: this.unit_code ? this.unit_code.id : null,
        on_date: this.on_date,
        place_from_name: this.place_from ? this.place_from.name : null,
        place_to_name: this.place_to ? this.place_to.name : null,
        unit_code_name: this.unit_code ? this.unit_code.name : null,
        complex_rate: false,
      }
      let routeData = this.$router.resolve({
        path: "/RateListPage",
        query: queryParams
      });

      if (this.$route.name === 'RateListPage') {
        if (this.$route.query.complex_rate === 'true' || Object.keys(this.$route.query).length === 0) {
          this.$router.replace(routeData.href);
          bus.$emit('show_one_rate', queryParams);
        } else {
          const isSamePath = this.$route.path === routeData.route.path;
          const isSameQuery = Object.keys(queryParams).every(
            (key) => String(this.$route.query[key]) === String(queryParams[key])
          );

          if (!isSamePath || !isSameQuery) {
            this.$router.replace(routeData.href);
          }

          bus.$emit('getratelist', queryParams);
        }
      } else {
        window.open(routeData.href, '_blank');
      }
    },
  
    changePlaceFrom(data) {
      let place = this.places_from.find(item => item.id == data);
      this.place_from = place
    },
  
    changePlaceTo(data) {
      let place = this.places_to.find(item => item.id == data);
      this.place_to = place
    },
    
    changeUnitCode(data) {
      let unit = this.unit_codes.find(item => item.id == data);
      this.unit_code = unit
    },
  
    changeDate() {
      const currentDate = new Date(this.on_date)

      currentDate.setMonth(currentDate.getMonth() + 1)
      if (currentDate.getDate() !== 1) {
        currentDate.setDate(1)
      }
      this.dateFormatted = this.formatDate(currentDate.toISOString().substr(0, 10))
      this.on_date = currentDate.toISOString().substr(0, 10)
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    getCurrentDay(){
      return new Date().toISOString().substring(0, 10)
    },

    getHolidaysInfo(){
      this.$postapi(this.$address + this.$getters.getHolidaysByCountry.uri, {
        method: this.$getters.getHolidaysByCountry.name,
        v_place_id: this.place_from.id,
      })
      .then(data => {
        if (data.error === 0) {
          this.holidaysData = JSON.parse(
            JSON.parse(data.message)[0].result
          ).filter((item) => {
            if (item.country_id == this.place_from.country_id) {
              return item
            }
          });
        }
      })
    },

    getCurrentSearchParams(){
      switch(this.currentUserId){
        case -1:
          if(this.$_getsetting('search-manager')){
            this.searchParams = JSON.parse(this.$_getsetting('search-manager'))
          }
        break;
        case -99:
          if(this.$_getsetting('search-nonauth')){
            this.searchParams = JSON.parse(this.$_getsetting('search-nonauth'))
          }
        break;
        default:
        if(this.$_getsetting('search-default')){
          this.searchParams = JSON.parse(this.$_getsetting('search-default'))
        }
        break;
      }

      this.place_from = this.searchParams.place_from
      this.place_to = this.searchParams.place_to
      this.unit_code = this.searchParams.unit_code

      if (new Date(this.searchParams.on_date) >= new Date()){
        this.dateFormatted = this.formatDate(this.searchParams.on_date)
        this.on_date = this.searchParams.on_date
      }else{
        this.dateFormatted = this.formatDate(this.on_date)
        this.searchParams.on_date = this.on_date
      }
    },

    selectDate(date) {
      this.showCalendar = false;
      this.dateFormatted = this.formatDate(date);
    },

    setCurrentSearchParams(){
      this.searchParams.place_from = this.place_from
      this.searchParams.place_to = this.place_to
      this.searchParams.unit_code = this.unit_code
      this.searchParams.on_date = this.on_date

      switch(this.currentUserId){
        case -1:
          this.$_setsetting('search-manager', JSON.stringify(this.searchParams))
          break;
        case -99:
          this.$_setsetting('search-nonauth', JSON.stringify(this.searchParams))
          break;
        default:
          this.$_setsetting('search-default', JSON.stringify(this.searchParams))
          break;
      }
    },

    clearInput(place) {
      switch (place){
        case 'place_from': 
          this.place_from = null
          break
          
        case 'place_to':
          this.place_to = null
          break
      }
    }
  },
}
</script>

<template>
  <div ref="searchRates">
    <v-form @submit.prevent="search()">
    <div class="form-container">
      <AppDropDown
        v-model="place_from"
        class="form-item"
        label="Место отправки"
        ref="place_from"
        :item-text="lang === 'en' ? 'name' : 'alt_name'"
        item-description="header"
        :options="places_from"
        :rules="inputRules"
        item-value="id"
        autocomplete
        placeholder="Выбрать"
        @changeInput="checkLang($event), changePlaceFrom($event)"
        @input="changePlaceFrom($event)"
        @clearInput="clearInput('place_from')"
        stickyCountry
        countryFlags
    />

    <AppDropDown
        v-model="place_to"
        class="form-item"
        label="Место доставки"
        ref="place_to"
        :item-text="lang === 'en' ? 'alt_name' : 'name'"
        item-description="header"
        :options="places_to"
        :rules="inputRules"
        autocomplete
        item-value="id"
        placeholder="Выбрать"
        @changeInput="checkLang($event), changePlaceTo($event)"
        @input="changePlaceTo($event)"
        @clearInput="clearInput('place_to')"
    />

    <AppMultiRadioButtons
      class="form-item"
      ref="unit_code"
      v-model="unit_code"
      :options="unit_codes"
      :rules="inputRules"
      item-text="name"
      label="Оборудование"
      tooltip
    />
   
    <div class="form-item date-container">
      <v-menu
        ref="showCalendar"
        v-model="showCalendar"
        :close-on-content-click="false"
        transition="scale-transition"
        color="var(--main-orange)"
        offset-y
        max-width="290px"
        min-width="auto"
        justify="right"
        left
      >
      <template v-slot:activator="{ on, attrs }">
        <AppInput
          label="Дата выхода"
          v-model="dateFormatted"
          :right-icon="require('../../assets/img/icons/calendar.svg')"
          v-bind="attrs"
          v-on="on"
          @click="showCalendar = true"
          disableInput
        />
      </template>
      <v-date-picker
        color="var(--main-orange)"
        v-model="on_date"
        first-day-of-week="1"
        no-title
        @input="selectDate"
        :min="getCurrentDay()"
      ></v-date-picker>
    </v-menu>
    
    <PublicHolidaysBtn v-if="place_from && place_from.country_id == 92" class="rate-panel__date-holidays" :holidays="holidaysData"/>
    </div>

    <div class="form-button">
      <AppButton
        label="Найти"
        class="button-container-button form-button"
        size="large"
        color="orange"
        :infoBtn="historyData.length > 0"
        @click="addMetrics('Search')"
      >
      <slot v-if="historyData.length > 0">
        <HistoryRateSearch
          class="button-container__history"
          @search="searchFromHistory($event)"
          :histories="historyData"
          />
      </slot>
      </AppButton>
    </div>
  </div>
  </v-form>
</div>
</template>

<style scoped lang="scss">

.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: $tablet-width) {
    justify-content: flex-start;
  }
}

.form-item {
  box-sizing: border-box;
  position: relative;
}

@media (min-width: 1201px) {
  .form-item:nth-child(1),
  .form-item:nth-child(2) {
    flex-grow: 1;
    flex-basis: 0;
  }
  .form-item:nth-child(3) {
    width: 287px;
  }
  .form-item:nth-child(4) {
    width: 200px;
  }
}

@media (max-width: $tablet-width) {
  .form-container {
    justify-content: space-between;
  }
  .form-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: $mobile-width) {
  .form-item {
    width: 100%
  }
}

.form-button {
  width: 1310px;
  display: flex;
  justify-content: flex-end; 
}

.button-container {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: flex-end;

  &-button {
    width: 160px;

    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }

  &__history {
    width: 270px;
    position: static;
  }
}

.date-container {
  margin-bottom: 16px;
}

.rate-panel__date-holidays{
  margin-top: -4px;
}
</style>
